import React from 'react';
import { graphql, Link } from 'gatsby';
import kebabCase from 'lodash/kebabCase';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import { Layout } from '@components';
import { IconBookmark } from '@components/icons';
import './zeearc.css';

const StyledMainContainer = styled.main`
  & > header {
    margin-bottom: 100px;
    text-align: center;

    a {
      &:hover,
      &:focus {
        cursor: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='40' height='48' viewport='0 0 100 100' style='fill:black;font-size:24px;'><text y='50%'>⚡</text></svg>")
            20 0,
          auto;
      }
    }
  }

  footer {
    ${({ theme }) => theme.mixins.flexBetween};
    width: 100%;
    margin-top: 20px;
  }
`;
const StyledGrid = styled.ul`
  ${({ theme }) => theme.mixins.resetList};
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 15px;
  margin-top: 50px;
  position: relative;

  @media (max-width: 1080px) {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
`;
const StyledPost = styled.li`
  transition: var(--transition);
  cursor: default;

  @media (prefers-reduced-motion: no-preference) {
    &:hover,
    &:focus-within {
      .post__inner {
        transform: translateY(-7px);
      }
    }
  }

  a {
    position: relative;
    z-index: 1;
  }

  .post__inner {
    ${({ theme }) => theme.mixins.boxShadow};
    ${({ theme }) => theme.mixins.flexBetween};
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    height: 100%;
    padding: 2rem 1.75rem;
    border-radius: var(--border-radius);
    transition: var(--transition);
    background-color: var(--light-navy);

    header,
    a {
      width: 100%;
    }
  }

  .post__icon {
    ${({ theme }) => theme.mixins.flexBetween};
    color: var(--green);
    margin-bottom: 30px;
    margin-left: -5px;

    svg {
      width: 40px;
      height: 40px;
    }
  }

  .post__title {
    margin: 0 0 10px;
    color: var(--lightest-slate);
    font-size: var(--fz-xxl);

    a {
      position: static;

      &:before {
        content: '';
        display: block;
        position: absolute;
        z-index: 0;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }
    }
  }

  .post__desc {
    color: var(--light-slate);
    font-size: 17px;
  }

  .post__date {
    color: var(--light-slate);
    font-family: var(--font-mono);
    font-size: var(--fz-xxs);
    text-transform: uppercase;
  }

  ul.post__tags {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      color: var(--green);
      font-family: var(--font-mono);
      font-size: var(--fz-xxs);
      line-height: 1.75;

      &:not(:last-of-type) {
        margin-right: 15px;
      }
    }
  }
`;

const PensievePageTwo = ({ location, data }) => {
  const posts = data.allMarkdownRemark.edges;

  return (
    <Layout location={location}>
      <Helmet title="Wire Frames" />

      <StyledMainContainer>
        <header>
          <h1 className="big-heading">Zee-Arc Furniture</h1>
          <p className="divcolor">
           To implement the design of a furniture web app that has branded products with strict branded guidlines.
          </p>

          <StaticImage
            className="imgxsa"
            src="../../images/furnituretest2.jpeg"
            width={1000}
            quality={100}
            height={667}
            formats={['AUTO', 'WEBP', 'AVIF']}
            alt="Headshot"
          />
        </header>
        <div className="">
          <h3>PROCESS</h3>
        </div>
        <StaticImage
          className="imgxsa"
          src="../../images/processfurniture.png"
          quality={100}
          formats={['AUTO', 'WEBP', 'AVIF']}
          alt="Headshot"
        />
        <div className="header-space-above-margin ">
          <h3>Zee-Arc Furniture APP</h3>
        </div>
        <p className="divcolor header-space-below-padding">
          Design of furniture which will surely make you fall in love with them..
        </p>
        <StaticImage
          className=""
          src="../../images/furnituretest.jpeg"
          width={1000}
          quality={100}
          formats={['AUTO', 'WEBP', 'AVIF']}
          alt="Headshot"
        />
        <p className="header-space-above-padding ">
          This app lets users buy branded products with discounts and the provision for the users to
          buy products based on their rating.Unlike other apps it consists only branded products
          approved and sold by ZeeArc organization,which makes it easy for the users to buy standard
          goods.
        </p>
        <div className="header-space-above-margin ">
          <h3>OUR PROBLEM</h3>
          <p className="divcolor ">
            The users across are finding it hard to find good and standard products as users have to
            invest a lot of time and effort to search and shortlist good branded products from
            e-commerce websites,so the need of an app with only branded products is necessary.
          </p>
          <p className=" ">
            How might we design an interface that is unique,but still connects to the users that is
            compact enough to give users focus on the core problem ?
          </p>
        </div>
        <div className="header-space-above-margin ">
          <h3 className="header-space-above-padding ">GETTING ORIENTED</h3>
          <p className="divcolor ">
            After our first meeting with some of the founders,my UI design partner and i delved into
            world of Zee-Arc Furniture to gather insights and an understanding of the App.
          </p>
          <StaticImage
            className="imgxsa"
            src="../../images/furnituretest4.jpg"
            width={1000}
            height={800}
            quality={100}
            formats={['AUTO', 'WEBP', 'AVIF']}
            alt="Headshot"
          />
        </div>
        <div className="divcolor header-space-above-margin ">
          <p>
            The various stages of design process from the starting stage to the final design which
            include personas,problem statement,insights,usability studies,low fidelity wireframe and
            prototypes, high fidelity prototypes and mockups are classified and listed below{' '}
          </p>
        </div>
        <StyledGrid>
          {posts.length > 0 &&
            posts.map(({ node }, i) => {
              const { frontmatter } = node;
              const { title, description, slug, date, tags } = frontmatter;
              const formattedDate = new Date(date).toLocaleDateString();

              return (
                <StyledPost key={i}>
                  <div className="post__inner">
                    <header>
                      <div className="post__icon">
                        <IconBookmark />
                      </div>
                      <h5 className="post__title">
                        <Link to={slug}>{title}</Link>
                      </h5>
                      <p className="post__desc">{description}</p>
                    </header>

                    <footer>
                      <span className="post__date">{formattedDate}</span>
                    </footer>
                  </div>
                </StyledPost>
              );
            })}
        </StyledGrid>
        <h3 className="header-space-above-margin">Final Thoughts</h3>
        <p className="divcolor header-space-below-padding">An exceptional specimen</p>
        <div className="  ">
          <p>
            In the end, the team was extremely proud of the designs we'd created
             and how far we'd come in just such a short span of time.
            Bernado was very pleased with the end result and was enthusiastic about seeing a product
            he'd been imagining brought to life.
          </p>
        </div>
        <StaticImage
          className="imgxsa"
          src="../../images/furniture.jpg"
          width={1000}
          quality={100}
          formats={['AUTO', 'WEBP', 'AVIF']}
          alt="Headshot"
        />
        <div className="header-space-above-padding">
          <p>
          "Thank you guys for all your hard work. I'm excited to show your work to the team and my mentors for their thoughts."
          </p>
        </div>
        <p className="divcolor">Bernado Silva, CEO/Founder</p>
      </StyledMainContainer>
    </Layout>
  );
};

PensievePageTwo.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};

export default PensievePageTwo;

export const pageQuery = graphql`
  {
    allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/content/posts/zeearc/" }
        frontmatter: { draft: { ne: true } }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            title
            description
            slug
            date
            tags
            draft
          }
          html
        }
      }
    }
  }
`;
